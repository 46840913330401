<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg5 xl4>
            
            <v-dialog v-model="recuperar" max-width="600px"
            persistent
            transition="dialog-top-transition"
            >
                      <v-card>
                        <v-card-title>
                          <span class="headline"> Ingrese su Usuario</span>
                          </v-card-title>
                            <v-card-text>
                                <v-container grid-list-md>
                                    <v-layout wrap>
                                        <v-flex xs12 sm12 md12 lg12 xl12>
                                             <v-text-field 
                                                 class="text-xs-center" v-model="texto"
                                                 @keyup.enter="listararticulo()">
                                            </v-text-field>
                                          <template>  
                                            </template>
                                        </v-flex>
                                      </v-layout>
                                  </v-container>
                              </v-card-text>
                            <v-card-actions>
                                  <v-btn @click="precuperar()" color="primary" dark class="mb-2" >Aceptar </v-btn>
                                  <v-btn @click="ocultarrecuperar()" color="primary" dark class="mb-2" >Cancelar </v-btn>
                              </v-card-actions>
                        </v-card>

                    </v-dialog >
            <v-card>
                <v-toolbar dark color="blue darken-3">
                    <v-toolbar-title>
                        Acceso al Sistema
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="email" autofocus color="accent" label="Email" required>
                    </v-text-field>
                    <v-text-field @keypress.enter="ingresar()" v-model="password" type="password" color="accent" label="Password" required>
                    </v-text-field>
                    <v-flex class="red--text" v-if="error">
                        {{error}}
                    </v-flex>
                </v-card-text>
                <v-card-actions class="px-3 pb-3">
                    <v-flex text-xs-right>
                            <v-btn @click="ingresar" color="primary">Ingresar</v-btn>   
                     </v-flex>
                     <v-flex text-xs-right>
                            <v-btn @click="mostrarrecuperar" color="primary">¿Olvidó su contraseña?</v-btn>   
                     </v-flex>

                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            email: '',
            password: '',
            error: null,
            recuperar:'',
            texto:''
        }
    },
    methods :{

        mostrarrecuperar(){
            this.recuperar=1
        },

        ocultarrecuperar(){
            this.recuperar=''
        },

        ingresar(){
            if (this.email.length<=5 || this.password.length<=2 ){
                    this.error="Verificar sus Datos";
            }
            else{
            this.error=null;
            axios.post('api/Usuarios/Login', {email: this.email, password: this.password})
            .then(respuesta => {
                return respuesta.data
            
            })
            .then(data => {        
                this.$store.dispatch("guardarToken", data.token)
                this.$router.push({ name: 'Home' })
            })
            .catch(err => {
                //console.log(err.response);
                if (err.response.status==400){
                    this.error="No es un email válido";
                } else if (err.response.status==404){
                    this.error="No existe el usuario o sus datos son incorrectos";
                }else{
                    this.error="Ocurrió un error";
                }
                //console.log(err)
            })
            }
        },

        precuperar(){
            if (this.texto.length<=5){
                    alert("Verificar sus Datos");
                    return;
            }
            else{
            this.error=null;
            axios.put('api/Usuarios/RecuperarPWS/'+this.texto, {})
            .then(respuesta => {
                return respuesta.data
            
            })
            .then(data => {  
                this.ocultarrecuperar();
                this.error="Se envio un Correo con su contraseña";
               // this.$store.dispatch("guardarToken", data.token)
               // this.$router.push({ name: 'Home' })
            })
            
            .catch(err => {
                //console.log(err.response);
                this.ocultarrecuperar();

                this.error="Se envio un Correo con su contraseña";
                
                
               // if (err.response.status==400){
             //       this.error="No es un email válido";
             //   } else if (err.response.status==404){
              //this.error="No existe el usuario o sus datos son incorrectos";
              //  }else{
                //  console.log()
                  //  this.error="Ocurrió un error";
               // }
                //console.log(err)
            })
             }
        }
    }
    
}
</script>