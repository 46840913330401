<template>
  <v-container grid-list-m>
    <v-layout wrap>
      <v-flex xs12 sm12 md12>
          <v-container >
             <v-layout>
                <v-flex>
                  <v-card>
                    <v-card-title>
                      <h3> Venta Anual</h3>
                      </v-card-title>
                      <v-expansion-panels accordion>
                        <v-expansion-panel 
                          v-for="(item,i) in 1"
                          :key="i"
                        >
                          <v-expansion-panel-header @click="Ventasfechaano()"><h2 class="green--text">{{new Intl.NumberFormat("en-US").format(TAnual.toFixed(2))}}</h2></v-expansion-panel-header>
                          <v-expansion-panel-content>                            
                            <template>  
                                <v-data-table
                                    :headers="DetallesDia"
                                    :items="Valoresano"
                                    class="elevation-1"
                                    hide-default-footer
                                    >         
                                           <template v-slot:[`item.monto`]="{ item }" class="justify-center layout px-0">  
                                              {{new Intl.NumberFormat("en-US").format(item.monto.toFixed(2))}}
                                            </template>
                                           
                                                <template v-slot:[`item.caja`]="{ item }">
                                                  <div v-if="item.caja=='C1'">
                                                    <span class="blue--text">SPS</span>
                                                  </div>
                                                  <div v-else>
                                                    <span class="blue--text">TGU</span>
                                                  </div>
                                                  </template>
                                                <template v-slot:[`item.tipo`]="{ item }">
                                                  <div v-if="item.tipo=='1'">
                                                    <span class="green--text">Contado</span>
                                                  </div>
                                                  <div v-else>
                                                    <span class="red--text">Credito</span>
                                                  </div>
                                                  </template>
                                    <template slot="no-data">
                                        <h3>No hay artículos para Mostrar.</h3>
                                    </template>
                                </v-data-table>
                              </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                  </v-card>
              </v-flex>
              <v-flex>
              <v-card>
                <v-card-title>
                  <h3> Venta Mes</h3>
                </v-card-title>
                      <v-expansion-panels accordion>
                        <v-expansion-panel 
                          v-for="(item,i) in 1"
                          :key="i"
                        >
                          <v-expansion-panel-header @click="Ventasfechames()"><h2 class="green--text">{{new Intl.NumberFormat("en-US").format(TMes.toFixed(2))}}</h2></v-expansion-panel-header>
                          <v-expansion-panel-content>                            
                            <template>  
                                <v-data-table
                                    :headers="DetallesDia"
                                    :items="Valoresmes"
                                    class="elevation-1"
                                    hide-default-footer
                                    >         
                                           <template v-slot:[`item.monto`]="{ item }" class="justify-center layout px-0">  
                                              {{new Intl.NumberFormat("en-US").format(item.monto.toFixed(2))}}
                                            </template>
                                           
                                                <template v-slot:[`item.caja`]="{ item }">
                                                  <div v-if="item.caja=='C1'">
                                                    <span class="blue--text">SPS</span>
                                                  </div>
                                                  <div v-else>
                                                    <span class="blue--text">TGU</span>
                                                  </div>
                                                  </template>
                                                <template v-slot:[`item.tipo`]="{ item }">
                                                  <div v-if="item.tipo=='1'">
                                                    <span class="green--text">Contado</span>
                                                  </div>
                                                  <div v-else>
                                                    <span class="red--text">Credito</span>
                                                  </div>
                                                  </template>
                                    <template slot="no-data">
                                        <h3>No hay artículos para Mostrar.</h3>
                                    </template>
                                </v-data-table>
                              </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
              </v-card>
              </v-flex>
              <v-flex>
                <v-card>
                  <v-card-title>
                    <h3> Venta Dia</h3>
                  </v-card-title>
                      <v-expansion-panels accordion>
                        <v-expansion-panel 
                          v-for="(item,i) in 1"
                          :key="i"
                        >
                          <v-expansion-panel-header @click="Ventasfecha()"><h2 class="green--text">{{new Intl.NumberFormat("en-US").format(TDia.toFixed(2))}}</h2></v-expansion-panel-header>
                          <v-expansion-panel-content>                            
                            <template>  
                                <v-data-table
                                    :headers="DetallesDia"
                                    :items="ValoresDia"
                                    class="elevation-1"
                                    hide-default-footer
                                    >         
                                           <template v-slot:[`item.monto`]="{ item }" class="justify-center layout px-0">  
                                              {{new Intl.NumberFormat("en-US").format(item.monto.toFixed(2))}}
                                            </template>
                                           
                                                <template v-slot:[`item.caja`]="{ item }">
                                                  <div v-if="item.caja=='C1'">
                                                    <span class="blue--text">SPS</span>
                                                  </div>
                                                  <div v-else>
                                                    <span class="blue--text">TGU</span>
                                                  </div>
                                                  </template>
                                                <template v-slot:[`item.tipo`]="{ item }">
                                                  <div v-if="item.tipo=='1'">
                                                    <span class="green--text">Contado</span>
                                                  </div>
                                                  <div v-else>
                                                    <span class="red--text">Credito</span>
                                                  </div>
                                                  </template>
                                    <template slot="no-data">
                                        <h3>No hay artículos para Mostrar.</h3>
                                    </template>
                                </v-data-table>
                              </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                </v-card>
              </v-flex>
              </v-layout>
              <v-layout>
              <v-flex>
              <v-card>
                <v-card-title>
                  <h3> Clientes: Top 10  Año {{Fecha.getFullYear()}} </h3>
                </v-card-title>
                      <v-expansion-panels accordion>
                        <v-expansion-panel 
                          v-for="(item,i) in 1"
                          :key="i"
                        >
                          <v-expansion-panel-header><h2 class="green--text">{{new Intl.NumberFormat("en-US").format(TTopClientes.toFixed(2))}} ({{((TTopClientes/TAnual)*100).toFixed(2)}}%)</h2></v-expansion-panel-header>
                          <v-expansion-panel-content>                            
                            <template>  
                                <v-data-table
                                    :headers="DetallesClientes"
                                    :items="Valoresclientes"
                                    class="elevation-1"
                                    hide-default-footer
                                    >         
                                           <template v-slot:[`item.monto`]="{ item }" class="justify-center layout px-0">  
                                              {{new Intl.NumberFormat("en-US").format(item.monto.toFixed(2))}}
                                            </template>
                                           
                                                <template v-slot:[`item.caja`]="{ item }">
                                                  <div v-if="item.caja=='C1'">
                                                    <span class="blue--text">SPS</span>
                                                  </div>
                                                  <div v-else>
                                                    <span class="blue--text">TGU</span>
                                                  </div>
                                                  </template>
                                                <template v-slot:[`item.tipo`]="{ item }">
                                                  <div v-if="item.tipo=='1'">
                                                    <span class="green--text">Contado</span>
                                                  </div>
                                                  <div v-else>
                                                    <span class="red--text">Credito</span>
                                                  </div>
                                                  </template>
                                    <template slot="no-data">
                                        <h3>No hay artículos para Mostrar.</h3>
                                    </template>
                                </v-data-table>
                              </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
              </v-card>
              </v-flex>
             </v-layout>
        </v-container>
<div class="container">
  <div class="row">
       <div class="col">
                <v-flex xs12 sm12 md12>
                  <v-card>
                    <v-card-text>
                        <div class="row">
                          <canvas id="myChart"></canvas>
                        </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
        </div>
    </div>
  <div class="row">
      <div class="col">
                <v-flex xs12 sm12 md12>
                  <v-card>
                    <v-card-title>
                      <h4>Promedio: {{new Intl.NumberFormat("en-US").format((tdias/15).toFixed(2))}}</h4>
                      </v-card-title>
                    <v-card-text>
                        <div class="row">
                          <canvas id="myChart2"></canvas>
                        </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
    </div>
       <div class="col">
                <v-flex xs12 sm12 md12>
                  <v-card>
                    <v-card-title>
                      <h4>Ventas Por Vendedor del Mes de {{meses[(Fecha.getMonth()+1)]}}</h4>
                      </v-card-title>
                    <v-card-text>
                        <div class="row">
                          <canvas id="myChart3"></canvas>
                        </div>
                    </v-card-text>
                   
                  </v-card>
                </v-flex>
      </div>
    </div>
     <div class="row">
       <div class="col">
             <v-container>
                  <v-layout>
              <v-flex>
              <v-card>
                <v-card-title>
                  <h3> Top Productos: </h3>
                </v-card-title>
                      <v-expansion-panels accordion>
                        <v-expansion-panel 
                          v-for="(item,i) in 1"
                          :key="i"
                        >
                          <v-expansion-panel-header><h2 class="green--text">Mas Vendidos:{{new Intl.NumberFormat("en-US").format(TTopProductosV.toFixed(2))}} ({{((TTopProductosV/TAnual)*100).toFixed(2)}}%)</h2></v-expansion-panel-header>
                          <v-expansion-panel-content>                            
                            <template>  
                                <v-data-table
                                    :headers="DetallesTopProductos"
                                    :items="ValoresProductos"
                                    class="elevation-1"
                                    hide-default-footer
                                    >         
                                    <template v-slot:[`item.monto`]="{ item }" class="justify-center layout px-0">  
                                              {{new Intl.NumberFormat("en-US").format(item.monto.toFixed(2))}}
                                            </template>

                                            <template v-slot:[`item.cantidad`]="{ item }" class="justify-center layout px-0">  
                                              {{new Intl.NumberFormat("en-US").format(item.cantidad.toFixed(2))}}
                                            </template>
                                    <template slot="no-data">
                                        <h3>No hay artículos para Mostrar.</h3>
                                    </template>
                                </v-data-table>
                              </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                  </v-card>
                  </v-flex>
                </v-layout>
            </v-container>
        </div>
    </div>

    <div class="row">
        <div class="col">
                  <v-flex xs13 sm13 md13>
                    <v-card>
                      <v-card-text>
                          <div class="row">
                            <canvas  id="myChart4"></canvas>
                          </div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
          </div>
          <div class="col">
                  <v-flex xs10 sm10 md10>
                    <v-card>
                      <v-card-title>
                        Detalle Por Categorias
                        </v-card-title>
                      <v-card-text>
                          <div class="row">
                            <v-data-table
                                    :headers="DetallesarrayCat"
                                    :items="arrayCat"
                                    class="elevation-1"
                                    :items-per-page="8"
                                    >         
                                    <template v-slot:[`item.valor`]="{ item }" class="justify-center layout px-0">  
                                              {{new Intl.NumberFormat("en-US").format(item.valor.toFixed(2))}}
                                            </template>

                                            
                                    <template slot="no-data">
                                      

                                      
                                        <h3>No hay artículos para Mostrar.</h3>
                                    </template>
                                </v-data-table>
                          </div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
          </div>
      </div>


  </div>

  
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);
export default {
  data(){
    return {
      mesesValores:null,
      nombreMeses:[],
      totalMeses:[],
      anoValores:null,
      nombreano:[],
      totalano:[],
      meses:["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      vendedorValores:null,
      nombrevendedor:[],
      totalvendedor:[],
      ValoresDia:[],
      Valoresano:[],
      Valoresmes:[],
      categoriasValores:[],
      arrayCat:[],
       DetallesarrayCat: [
        { text: 'Categoria', value: 'etiqueta', sortable: false },
        { text: 'Monto', value: 'valor' }
      ],
      totalcategoria:[],
      nombrecategoria:[],
      Valoresclientes:[],
      periodos: ['Ventas Ultimos 15 dias','Ventas Anuales', 'Ventas x Vendedor'],
      TAnual:0,
      TTopClientes:0,
      TTopProductosV:0,
      TTopProductosC:0,
      Fecha:new Date(),
      TMes:0,
      TDia:0,
      TCategoria:0,
      TMinimoCategoria:0,
      tdias:0,
      DetallesDia: [
        { text: 'Caja', value: 'caja', sortable: false },
        { text: 'Codigo', value: 'tipo', sortable: false },
        { text: 'Monto', value: 'monto', sortable: false },
        { text: '%', value: 'Porcentaje', sortable: false }
      ],
      DetallesClientes: [
        { text: 'Cliente', value: 'ncliente', sortable: false },
        { text: 'Monto', value: 'monto' },
        { text: '%', value: 'Porcentaje'}
      ],
      ValoresProductos:[],
      DetallesTopProductos: [
        { text: 'Nombre', value: 'nproducto', sortable: false },
        { text: 'Uni_Vendidas', value: 'cantidad' },
        { text: 'Monto', value: 'monto' }
      ],
      UtilidadProductos:[],
      DetalleUtilidadProductos: [
      { text: 'Nombre', value: 'nproducto', sortable: false },
      { text: 'Monto Facturado', value: 'monto' },
      { text: '%', value: 'porcentaje' }
    ],

    }
  },
  methods:{
    loadVentasAnuales(){
      let me=this;
      let mesn='';
      me.anoValores.map(function(x){
        switch(parseInt(x.etiqueta)){
          case 1:
            mesn='Enero';
            break;
          case 2:
            mesn='Febrero';
            break;
          case 3:
            mesn='Marzo';
            break;
          case 4:
            mesn='Abril';
            break;
          case 5:
            mesn='Mayo';
            break;
          case 6:
            mesn='Junio';
            break;
          case 7:
            mesn='Julio';
            break;
          case 8:
            mesn='Agosto';
            break;
          case 9:
            mesn='Setiembre';
            break;
          case 10:
            mesn='Octubre';
            break;
          case 11:
            mesn='Noviembre';
            break;
          case 12:
            mesn='Diciembre';
            break;
          default:
            mesn='Error';
        }
        me.nombreano.push(mesn);
        me.totalano.push(x.valor);
        me.TAnual=me.TAnual+x.valor;
        if(me.Fecha.getMonth()+1==x.etiqueta){
            me.TMes=x.valor;
            
        }
  
      });
      var ctx = document.getElementById("myChart");
      var delayed;
      var myChart = new Chart(ctx, {
          type: 'bar',
          data: {
              labels: me.nombreano,
              datasets: [{
                  label: 'Ventas en los últimos 12 Meses',
                  data: me.totalano,
                  backgroundColor: [
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)',                 
                      'rgb(39, 174, 96)',
                      'rgb(230, 126, 34)',
                      'rgb(218, 247, 166)',
                      'rgb(125, 60, 152)',
                      'rgb(217, 136, 128)',
                      'rgb(245, 183, 177)',
                      'rgb(84, 153, 199)',
                      'rgb(118, 215, 196)',
                      'rgb(255, 206, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(153, 102, 255)',
                      'rgb(255, 159, 64)'
                  ],
                  borderColor: [
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)'
                  ],
                  borderWidth: 2,
                  borderRadius: 20,
                  borderSkipped: false
              }]
          },
          options: {
             animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 600 + context.datasetIndex * 100;
        }
        return delay;
      },},
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero:true
                      }
                  }]
              }
          }
      });
      this.Ventasfechaclientes();
      this.VentasProductosMeses12()
    },

     loadVentasMensual(){
      let me=this;
      let mesn='';
      me.mesesValores.map(function(x){
          //alert(me.Fecha.getDate())
        if(me.Fecha.getDate()==parseInt(x.etiqueta)){
            me.TDia=x.valor;
        }
        me.tdias=x.valor+me.tdias
        me.nombreMeses.push(x.etiqueta);
        me.totalMeses.push(x.valor);
      });
      var ctx = document.getElementById("myChart2");
      var myChart = new Chart(ctx, {
          type: 'line',
          data: {
              labels: me.nombreMeses,
              datasets: [{
                  label: 'Ventas en los últimos 15 Dias',
                  data: me.totalMeses,
                  backgroundColor: [
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)',                 
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(153, 102, 255)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(153, 102, 255)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)'                 
                  ],
                  borderColor: [
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)'
                  ],
                  borderWidth: 2,
                  borderRadius: 20,
                  borderSkipped: false
              }]
          },
          options: {
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero:true
                      }
                  }]
              }
          }
      });
    },

    loadcategorias(){
      let me=this;
      me.TMinimoCategoria=0;
      me.categoriasValores.map(function(x){
          //alert(me.Fecha.getDate())
        if((((x.valor/me.TCategoria)*100).toFixed(2))>3){
          me.nombrecategoria.push(x.etiqueta+(((x.valor/me.TCategoria)*100).toFixed(2))+'%');
          me.totalcategoria.push(x.valor/me.TCategoria);
        }
        else{
          me.TMinimoCategoria=(x.valor/me.TCategoria)+me.TMinimoCategoria
        }

      });
      me.nombrecategoria.push('Otros'+(((me.TMinimoCategoria)*100).toFixed(2))+'%')
      me.totalcategoria.push(me.TMinimoCategoria);
      var ctx = document.getElementById("myChart4");
      var myChart = new Chart(ctx, {
          type: 'pie',
          data: {
              labels: me.nombrecategoria,
              datasets: [{
                  label: 'Ventas Por Categorias',
                  data: me.totalcategoria,
                  backgroundColor: [
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)',                 
                      'rgb(39, 174, 96)',
                      'rgb(230, 126, 34)',
                      'rgb(218, 247, 166)',
                      'rgb(125, 60, 152)',
                      'rgb(217, 136, 128)',
                      'rgb(245, 183, 177)',
                      'rgb(84, 153, 199)',
                      'rgb(118, 215, 196)',
                      'rgb(255, 206, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(153, 102, 255)',
                      'rgb(255, 159, 64)'                 
                  ],
                  borderColor: [
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)'
                  ],
                  borderWidth: 2,
                  
                  
              }]
          },
          options: {
             responsive: true,
              scales: {
                
                  yAxes: [{
                      ticks: {
                          beginAtZero:true
                      }
                  }]
              }
          }
      });
    },
    
     loadVentasVendedor(){
      let me=this;
      let mesn='';
      const d = new Date();
      me.vendedorValores.map(function(x){
        me.nombrevendedor.push(x.etiqueta);
        me.totalvendedor.push(x.valor);
      });
      var ctx = document.getElementById("myChart3");
      var myChart = new Chart(ctx, {
          type: 'line',
          data: {
              labels: me.nombrevendedor,
              datasets: [{
                  label: 'Top 10',
                  data: me.totalvendedor,
                  backgroundColor: [
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)',                 
                      'rgb(39, 174, 96)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(153, 102, 255)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(153, 102, 255)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                      'rgb(255, 206, 86)'  
                  ],
                  borderColor: [
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)'
                  ],
                  borderWidth: 2,
                  borderRadius: 20,
                  borderSkipped: false
              }]
          },
          options: {
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero:true
                      }
                  }]
              }
          }
      });
    },
    
    limpiar(){
      document.getElementById("divcanvas").innerHTML = '&nbsp;';
      document.getElementById("divcanvas").innerHTML = '<canvas id="myChart"></canvas>';
      let me=this;
      me.mesesValores=[];
      me.nombreMeses=[];
      me.totalMeses=[];
    },

    GraficoSeleccionado(items)
    {      
     
      if(items=='Ventas Anuales'){
        this.limpiar()
        this.VentasAnuales();
      }
      if(items=='Ventas Ultimos 15 dias'){
        this.limpiar()
        this.VentasMensual();
      }
      if(items=='Ventas x Vendedor'){
        this.limpiar();
        this.VentasVendedor();
      }
    },

    VentasAnuales(){
      let me=this;
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
      axios.get('api/VST_VentasTotales/VentasMes12',configuracion).then(function(response){
          //console.log(response);
          me.anoValores=response.data;
          me.loadVentasAnuales();
      }).catch(function(error){
          console.log(error);
      });
    },

VentasMensual(){
      let me=this;
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
      axios.get('api/VST_VentasTotales/Ventasdias',configuracion).then(function(response){
          //console.log(response);
          me.mesesValores=response.data;
          me.loadVentasMensual();
      }).catch(function(error){
          console.log(error);
      });
    },


VentasVendedor(){
      let me=this;
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
      axios.get('api/VST_VentasTotales/Ventasvendedor',configuracion).then(function(response){
          //console.log(response);
          me.vendedorValores=response.data;
          me.loadVentasVendedor();
      }).catch(function(error){
          console.log(error);
      });
    },


Ventascategoria(){
      let me=this;
      me.arrayCat=[];
      me.TCategoria=0;
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
      axios.get('api/VST_VentasxProductos/VentasCostosxCategoria',configuracion).then(function(response){
          //console.log(response);
          me.arrayCat=response.data;
            me.arrayCat.map(function(x){
              me.TCategoria=me.TCategoria+x.valor
              //alert(me.TCategoria)
            me.categoriasValores.push(
              {etiqueta:x.etiqueta,
              valor:x.valor,
              });
          });
          me.loadcategorias();
      }).catch(function(error){
          console.log(error);
      });
    },

Ventasfecha(){
  let me=this;
  me.ValoresDia=[]
    var date = me.Fecha.getDate();
    var month = me.Fecha.getMonth(); //Be careful! January is 0 not 1
    var year = me.Fecha.getFullYear();
    var dateinicio =  year+"-"+(month + 1) + "-" + date+"T00:00:00";
    var datefin =  year+"-"+(month + 1) + "-" + date+"T23:59:59";
      var ArrayDia=[];
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
      axios.get('api/VST_VentasTotales/VentasCajaTipo/'+dateinicio+'/'+datefin,configuracion).then(function(response){
          ArrayDia=response.data;
          ArrayDia.map(function(x){
            me.ValoresDia.push(
              {caja:x.caja,
              tipo:x.tipo,
              monto:x.valor,
              Porcentaje: ((x.valor/me.TDia)*100).toFixed(2)
              });
          });
          
      }).catch(function(error){
          console.log(error);
      });
    },

Ventasfechames(){
  let me=this;
  me.Valoresmes=[]
  let today = new Date()
  let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
    var date = me.Fecha.getDate();
    var month = me.Fecha.getMonth(); //Be careful! January is 0 not 1
    var year = me.Fecha.getFullYear();
    var dateStringI =  year+"-"+(month + 1) + "-01" ;
    var dateStringF =  year+"-"+(month + 1) + "-" + lastDay;
      var Arraymes=[];
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
      axios.get('api/VST_VentasTotales/VentasCajaAMes/'+dateStringI+'/'+dateStringF,configuracion).then(function(response){
         // console.log(response);
          Arraymes=response.data;
          Arraymes.map(function(x){
            me.Valoresmes.push(
              {caja:x.caja,
              tipo:x.tipo,
              monto:x.valor,
              Porcentaje: ((x.valor/me.TMes)*100).toFixed(2)
              });
          });
          //me.loadVentasVendedor();
      }).catch(function(error){
          console.log(error);
      });
    },

  Ventasfechaano(){
  let me=this;
  me.Valoresano=[]
  let today = new Date()
    var year = me.Fecha.getFullYear();
    var dateStringI = year+"-01-01";
    var dateStringF =  year+"-12-31";
      var Arraymes=[];
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
      axios.get('api/VST_VentasTotales/VentasCajaAAno/'+dateStringI+'/'+dateStringF,configuracion).then(function(response){
          //console.log(response);
          Arraymes=response.data;
          Arraymes.map(function(x){
            me.Valoresano.push(
              {caja:x.caja,
              tipo:x.tipo,
              monto:x.valor,
              Porcentaje: ((x.valor/me.TAnual)*100).toFixed(2)
              });
          });
          //me.loadVentasVendedor();
      }).catch(function(error){
          console.log(error);
      });
    },

Ventasfechaclientes(){
  let me=this;
  me.TTopClientes=0
  me.Valoresclientes=[]
  let today = new Date()
    var year = me.Fecha.getFullYear();
    var dateStringI = year+"-01-01";
    var dateStringF =  year+"-12-31";
      var Arraymes=[];
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
      axios.get('api/VST_VentasTotales/VentasxCliente/'+dateStringI+'/'+dateStringF,configuracion).then(function(response){
          console.log(response);
          Arraymes=response.data;
          Arraymes.map(function(x){
            me.TTopClientes=me.TTopClientes+x.valor;
            me.Valoresclientes.push(
              {ncliente:x.etiqueta,
              monto:x.valor,
              Porcentaje: ((x.valor/me.TAnual)*100).toFixed(2)
              });
          });
          //me.loadVentasVendedor();
      }).catch(function(error){
          console.log(error);
      });
    },
    
VentasProductosMeses12(){
  let me=this;
  me.TTopProductosV=0
  me.TopProductosC=0
  me.ValoresProductos=[]
      var Arraymes=[];
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
      axios.get('api/VST_VentasxProductos/VentasProductosMeses12/',configuracion).then(function(response){
          //console.log(response);
          Arraymes=response.data;
          Arraymes.map(function(x){
            me.TTopProductosV=me.TTopProductosV+x.valor;
            me.TopProductosC=me.TopProductosC+x.valor2;
            me.ValoresProductos.push(
              {producto:x.producto,
              nproducto:x.etiqueta,
              cantidad:x.valor,
              monto:x.valor2
              });
          }
          
          );
          //me.loadVentasVendedor();
      }).catch(function(error){
          console.log(error);
      });
    },


},





  mounted(){
    this.VentasAnuales();
    this.VentasMensual();
    this.VentasVendedor();
    this.Ventascategoria();    
  }
}


</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>
