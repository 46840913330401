<template>
    <v-data-table
      :headers="headers"
      :items="usuarios"      
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Usuarios</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >

            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nuevo
              </v-btn>
            
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                      <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.Nombre"
                        label="Nombre"
                      ></v-text-field>
                    </v-col>
                                   
                    <v-col
                      xs="12"
                      sm="6"
                      md="6"
                    >
                    <v-select v-model="idrol" :items="roles" 
                    label="Rol"
                    >
                    </v-select>
                    </v-col>

                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                    <v-select v-model="Tipo_Documento" :items="TDocumentos" 
                    label="Tipo de Documento"
                    >
                    </v-select>
                    </v-col>

                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.Num_Documento"
                        label="Numero de Documento"
                      ></v-text-field>
                      </v-col>

                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.Direccion"
                        label="Direccion"
                      ></v-text-field>
                      </v-col>

                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.Telefono"
                        label="Telefono"
                      ></v-text-field>
                      </v-col>

                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.Email"
                        label="Email"
                      ></v-text-field>
                      </v-col>

                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        type="password"
                        v-model="editedItem.Password"
                        label="Password"
                      ></v-text-field>
                      </v-col>

                    <v-text-field class="red--text" v-for="v in validamensaje" :key="v" v-text="v">                    
                    </v-text-field>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="Guardar"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
     

          <v-dialog v-model="addmodal" max-width="500px">
            <v-card>
              <v-card-title class="headline" v-if="addaction==1"> Desea Activar </v-card-title>
              <v-card-title class="headline" v-if="addaction==2"> Desea Desactivar </v-card-title>
              
              <v-card-text > Estas a Punto de: </v-card-text>
                <span v-if="addaction==1"> Activar </span>
                <span  v-if="addaction==2"> Desactivar</span>
                el item {{addnombre}}
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="activardesactivarcerrar">Cancel</v-btn>
                  <v-btn v-if="addaction==1" color="blue darken-1" text @click="activar">Activar</v-btn>
                  <v-btn v-if="addaction==2" color="blue darken-1" text @click="desactivar">Desactivar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>


          
        </v-toolbar>
      </template>
           
      
      <template v-slot:[`item.actions`]="{ item }">  
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
        <template v-if="item.condicion">
          <v-icon
            small
            
            @click="activardesacticarmostrar(2,item)"
          >
            block
          </v-icon>    
        </template>
        <template v-else>
          <v-icon
            small
            @click="activardesacticarmostrar(1,item)"
          >
            check
          </v-icon>    
        </template>

      </template>

     <template v-slot:[`item.condicion`]="{ item }">
        <div v-if="item.condicion">
          <span class="blue--text">Activo</span>
        </div>
        <div v-else>
          <span class="red--text">Inactivo</span>
        </div>
      </template>

                    


      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="listar"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
</template>


<script>

import axios from 'axios'
export default {

  data: () => ({
    
    search:'',
    dialogDelete: false,
    usuarios:[],
    headers: [
      { text: 'Acciones', value: 'actions', sortable: false },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Rol', value: 'rol' },
      { text: 'Tipo Documento', value: 'tipo_documento' },
      { text: 'Numero Documento', value: 'num_documento' , sortable: false  },
      { text: 'Telefono', value: 'telefono' , sortable: false  },
      { text: 'Correo', value: 'email' , sortable: false  },
      { text: 'Condicion', value: 'condicion' }
    ],
    dialog:false,
    editedIndex: -1,
    editedItem: {
      Num_Documento:'',
      Direccion:'',
      Telefono:'',
      Email:'',
      Password:'',
      ActPassword:false,
      Nombre:'',  
    },
    PasswordAnt:'',
    idrol:'',
    IdUsuario:'',
    Tipo_Documento:'',
    TDocumentos:['DNI','Pasaporte','RTN'], 
    roles:[],
      valida:0,
      validamensaje:[],
      addmodal:0,
      addaction:0,
      addnombre:'',
      adid:''
  }),
  



 computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editando Usuario'
    },
  },

  created () {
    this.listar()
    this.SelectRoles()
  },

   watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
    methods: {
        listar(){
                let me=this;
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                axios.get('api/Usuarios/Listar',configuracion).then(function(response){
                    me.usuarios=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },

   SelectRoles(){
                let me=this;  
                var rolesaray=[];
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                axios.get('api/Roles/Select',configuracion).then(function(response){
                    rolesaray=response.data;
                    rolesaray.map(function(x)
                    {
                      me.roles.push({text:x.nombre,value:x.idrol})
                    });


                }).catch(function(error){
                    console.log(error);
                });
            },

    editItem (item) {
      this.IdUsuario = item.idusuario;
      this.editedItem.Nombre = item.nombre;
      this.idrol=item.idrol;
      this.Tipo_Documento=item.tipo_documento;
      this.editedItem.Num_Documento=item.num_documento;
      this.editedItem.Direccion=item.direccion;
      this.editedItem.Telefono=item.telefono;
      this.editedItem.Email=item.email;
      this.editedItem.Password=item.password_hash;
      this.PasswordAnt=item.password_hash;
      this.editedIndex=1;
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    limpiar () {
      this.editedIndex=-1;
      this.editedItem.idrol="";
      this.editedItem.Direccion="";
      this.editedItem.Telefono="";
      this.editedItem.Email="";
      this.editedItem.Password="";
      this.editedItem.Nombre="";
      this.idrol="";
      this.editedItem.Num_Documento="";      
      this.PasswordAnt="";
      this.ActPassword=false;
    },

    validar () {
      this.valida=0;
      this.validamensaje=[];

      if (this.editedItem.Nombre.length<3 || this.editedItem.Nombre.length>100)
      {
          this.validamensaje.push("*El nombre debe tener mas de 3 Caracteres y Menos de 100 caracteres.");
      }


      if (!this.idrol ||this.idrol ==="")
      {
        this.validamensaje.push("*Selecione un Rol.");
      }
      
      if (!this.TDocumentos ||this.TDocumentos ==="")
      {
        this.validamensaje.push("*Selecione un Tipo de Documento.");
      }

       if (this.editedItem.Email.value)
      {
          this.validamensaje.push("*Ingrese un Email.");
      }

      if (this.editedItem.Password.value)
      {
          this.validamensaje.push("*Ingrese el Password del Usuario.");
      }
      

      if (this.validamensaje.length)
      {
        this.valida=1;
      }
      return this.valida;
    },

 activardesacticarmostrar (accion,item) {
      
      this.addmodal=1;
      this.addnombre=item.nombre;
      this.adid=item.idusuario;
      if (accion==1)
      {
        this.addaction=1;
      }
      else if(accion==2) {
        this.addaction=2;
      }
      else{
        this.addmodal=0;
      }
  
    },



activar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Usuarios/Activar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
    },

    

desactivar () {
  let me=this;
      axios.put('api/Usuarios/DesActivar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

activardesactivarcerrar () {
      this.addmodal=0;
    },



Guardar () {
      let ma=this;
      let header={"Authorization":"Bearer "+this.$store.state.token};
      let configuracion={headers:header};
      if (ma.validar()){
        return; 
      }
      if (this.editedIndex > -1) {
        //actualizar
        let me=this.editedItem;
        
        //actualizar Password
        if(me.Password!=this.PasswordAnt)
        {
          me.ActPassword=true;
        }

        axios.put('api/usuarios/Actualizar',{
          'idusuario':this.IdUsuario,
          'idrol':this.idrol,
          'nombre':me.Nombre,
          'tipo_documento':this.Tipo_Documento,
          'num_documento':me.Num_Documento,
          'direccion':me.Direccion,
          'telefono':me.Telefono,
          'email':me.Email,
          'password':me.Password,
          'act_password':me.ActPassword
          },configuracion).then(function(response)
            {
              ma.close();
              ma.listar();
              ma.limpiar()
            }).catch(function(error)
            {
              console.log(error);
            });


      } else {
        let me=this.editedItem;
        axios.post('api/Usuarios/Crear',
        {
          'idrol':this.idrol,
          'nombre':me.Nombre,
          'tipo_Documento':this.Tipo_Documento,
          'num_documento':me.Num_Documento,
          'direccion':me.Direccion,
          'telefono':me.Telefono,
          'direccion':me.Direccion,
          'email':me.Email,
          'password':me.Password
        },configuracion).then(function(response)
            {
              ma.close()
              ma.listar()
              ma.limpiar()  
            }).catch(function(error)
            {
              console.log(error);
            });
      }   
    },
  }, 
}



</script>