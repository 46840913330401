<template>    
    <v-layout align-start>
    <v-flex>
              <v-toolbar flat color="white">
                <v-toolbar-title>Resumen Ventas</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    

                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Fecha de Incicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                      
                      <v-dialog
                        ref="dialogfin"
                        v-model="modalfin"
                        :return-value.sync="datefin"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="datefin"
                            label="Fecha de Incicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datefin"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modalfin = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialogfin.save(datefin)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    
                    <v-spacer></v-spacer>
                    <v-btn  @click="obtenerprecioexistencias()" color="primary" dark class="mb-2">Consultar</v-btn>
                </v-toolbar> 
           <v-card >
    
    <v-card-title>
          <span class="green--text" >Suma Total:{{new Intl.NumberFormat("en-US").format((this.TCredito+this.TContado).toFixed(2))}}</span>
    </v-card-title>
    <v-card-text>
  <div style="margin-left:2%">
    <v-flex>
        <h2>Ventas al Contado</h2>
    </v-flex>
  <v-flex >
             <v-data-table
            :headers="headers"
            :items="contado"      
            class="elevation-1"
            :items-per-page="5"
          >
      <template v-slot:no-data>
        <h3>No hay Informacio para Mostrar.</h3>
      </template>
    </v-data-table>  
      <v-flex>
        <h3 class="blue--text">Total al Contado:{{new Intl.NumberFormat("en-US").format(this.TContado.toFixed(2))}}</h3>
      </v-flex>
    </v-flex >
    </div>
    </v-card-text>
  <div style="margin-left:2%">
    <v-flex>
        <h2>Ventas al Credito</h2>
    </v-flex>
    <v-card-text>
    <v-flex>
             <v-data-table
            :headers="headers"
            :items="credito"      
            class="elevation-1"
            :items-per-page="5"
            
          >
      <template v-slot:no-data>
        <h3>No hay Informacio para Mostrar.</h3>
      </template>
    </v-data-table> 
      <v-flex>
        <h3 class="blue--text">Total al Credito:{{new Intl.NumberFormat("en-US").format(this.TCredito.toFixed(2))}}</h3>
      </v-flex> 
    </v-flex>
    </v-card-text>
  </div>
  </v-card>
  
  </v-flex>

    </v-layout>          
</template>

<script>

import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export default {

  data: () => ({
    dialog: false,
    search:'',
    
    
    dialogDelete: false,
    credito:[],
    contado:[],
    headers: [
      { text: 'Factura', value: 'id',  sortable: false},
      { text: 'Cliente', value: 'ncliente' },
      { text: 'Fecha', value: 'fecha' },
      { text: 'Subtotal', value: 'subtotal' },
      { text: 'Impuesto', value: 'impuesto' },
      { text: 'Neto', value: 'neto' },
      { text: 'Tipo', value: 'tipo' }
    ],
    editedIndex: -1,
    editedItem: {
      Codigo:'',
      Nombre: '',
      Stock: 0,
      Precio_Venta: 0,
    },
    finicio:'',
    ffin:'',
    IdCategoria:'',
    IdProducto: '',
    loading:false,
    TCredito:0,
    TContado:0,
    TGeneral:0,
    categorias:[],
      valida:0,
      validamensaje:[],
      addmodal:0,
      addaction:0,
      addnombre:'',
      adid:'',
      campo_bloqueado:false,
      date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      datefin : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      modalfin: false
  }),
  



 computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Producto' : 'Editando Producto'
    },
  },

  created () {
    //this.obtenerprecioexistencias()
    //this.SelectCategoria()
  },

   watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
    methods: {

      /*/
            crearPDF(){
                var columns = [
                    {title: "Nombre", dataKey: "nombre"},
                    {title: "Código", dataKey: "producto"}, 
                    {title: "Categoría", dataKey: "ncategoria"}, 
                    {title: "Stock", dataKey: "stock"},
                    {title: "Precio Venta", dataKey: "precio_venta"}
                ];
                var rows = [];

                this.productos.map(function(x){
                    rows.push({nombre:x.nombre,producto:x.producto,ncategoria:x.ncategoria,stock:x.stock,precio_venta:x.precio_venta});
                });

                // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Artículos", 40, 30);
                    }
                });
                doc.save('Productos.pdf');
            },
 /*/
   


    close () {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },






    

activardesactivarcerrar () {
      this.addmodal=0;
    },

hoy() {
  const date = new Date();
  var dia = date.getDate();
  var mes = date.getMonth()+1;
  var ano = date.getFullYear();
  //date.toLocaleDateString()
  this.ffin=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
  alert(this.ffin)
},

 obtenerprecioexistencias()
          {
            if (this.date.length>1 && this.datefin.length>1 )
          {
          
          this.loading=true;
           let me=this; 
           me.contado=[];
           me.credito=[];
           me.TCredito=0;
           me.TContado=0;
           me.TGeneral=0;
            var arraydata=[];
           //me.existenciasuni=0;   
           let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                var fechahorainicio=this.date+'T00:00:00'
                var fechahorafin=this.datefin+'T23:59:59'
                console.log(fechahorainicio)
                console.log(fechahorafin)
                axios.get('api/VST_VentasTotales/ListarFechas/'+fechahorainicio+'/'+fechahorafin,configuracion).then(function(response){
                    //me.productos=response.data;
                    arraydata=response.data
                    if (arraydata.length<=0)
                    {
                      alert("No hay Registros con esa Fecha")
                      return 0;
                    }
                    
                    arraydata.map(function(x){
                      if(x.tipo=='1'){
                        me.TContado=x.neto+me.TContado
                        //alert(me.TCredito)
                        me.contado.push({
                        id:x.id,
                        ncliente:x.ncliente,
                        fecha:x.fecha,
                        stock:x.stock,
                        subtotal:x.subtotal,
                        impuesto:x.impuesto,
                        neto:x.neto,
                        tipo:x.tipo
                        });
                      }
                      else{
                        me.TCredito=x.neto+me.TCredito
                       // this.TCredito=(x.neto+this.TCredito)
                        me.credito.push({
                        id:x.id,
                        ncliente:x.ncliente,
                        fecha:x.fecha,
                        stock:x.stock,
                        subtotal:x.subtotal,
                        impuesto:x.impuesto,
                        neto:x.neto,
                        tipo:x.tipo
                        });
                      }
                      });
                        
                   // me.mostrarexistencias();
                }).catch(function(error){
                    console.log(error);
                    alert("Error al Consultar Producto")
                }).finally(() => this.loading = false);
          }
          else{
            alert("Verifique los campos de Fecha")
          }
          },

  }, 
}
</script>

<style>
h2 {
  text-align: center;
}
</style>