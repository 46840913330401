<template>
    <v-data-table
      :headers="headers"
      :items="clientes"
      sort-by="Idcliente"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Clientes</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            persistent
            max-width="1000px"
          >

            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nuevo
              </v-btn>
            
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.Nombre"
                        label="Nombre"
                      ></v-text-field>
                    </v-col >
                    
                    
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.Contacto"
                        label="Contacto"
                      ></v-text-field>
                    </v-col >
                    
                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                      v-mask="'(###)####-####'" 
                        v-model="editedItem.Telefono"
                        label="Telefono"
                      ></v-text-field>
                      </v-col>

                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-mask="'(###)####-####'" 
                        v-model="editedItem.Celular"
                        label="NºCelular"
                      ></v-text-field>
                    </v-col >
                    
                    <v-col
                      cols="12"
                      sm="6"
                      md="5"
                    >
                    <v-select v-model="editedItem.Idvendedor" :items="Vendedor" 
                    label="Vendedor"
                    >
                     </v-select>
                    
                    </v-col>
                  


                    <!-- Informacion de Contacto -->
                  <v-expansion-panels ac >
                  <v-expansion-panel :key="1" > 
                  <v-expansion-panel-header class="h3" color="#BBDEFB">Informacion Adicional del Cliente</v-expansion-panel-header>
                  <v-expansion-panel-content>
                      
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.Email"
                        label="Email"
                      ></v-text-field>
                      </v-col>
                  <v-row>
                  <v-col
                      cols="5"
                      sm="5"
                      md="5"
                    >
                    <v-select v-model="Tipo_Documento" :items="TDocumentos" 
                    label="Tipo de Documento"
                    >
                    </v-select>
                    </v-col>

                    <v-col
                      cols="5"
                      sm="5"
                      md="5"
                    >
                      <v-text-field
                        v-mask="'####-####-######'" 
                        v-model="editedItem.Num_Documento"
                        label="Numero de Documento"
                      ></v-text-field>
                      </v-col>
                   </v-row>


                  <v-row>
                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                    <v-select v-model="editedItem.Tcliente" :items="RCliente" 
                    label="Razon Social"
                    >
                     </v-select>
                    
                    </v-col>

                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                    <v-select v-model="editedItem.Idclasificacion" :items="Clasificacion" 
                    label="Clasificacion"
                    >
                     </v-select>
                    
                    </v-col>
                  </v-row>
                   <v-row> 
                     <v-col
                      cols="5"
                      sm="5"
                      md="5"
                    >
                      <v-text-field
                        v-model="editedItem.Gerente"
                        label="Gerente"
                      ></v-text-field>
                      </v-col>
                   

                    <v-col
                      cols="5"
                      sm="5"
                      md="5"
                    >
                      <v-text-field
                        v-model="editedItem.Gercel"
                        label="# Telefono del Gerente"
                      ></v-text-field>
                      </v-col>
                   </v-row>

                    <v-row> 
                     <v-col
                      cols="5"
                      sm="5"
                      md="5"
                    >
                      <v-text-field
                        v-model="editedItem.Pagador"
                        label="Persona de Pagos"
                      ></v-text-field>
                      </v-col>
                   

                    <v-col
                      cols="5"
                      sm="5"
                      md="5"
                    >
                      <v-text-field
                        v-model="editedItem.Pagcel"
                        label="# Telefono Pesona de Pagos"
                      ></v-text-field>
                      </v-col>
                   </v-row>


                    <v-row> 
                     <v-col
                      cols="5"
                      sm="5"
                      md="5"
                    >
                      <v-text-field
                        v-model="editedItem.Mantenimiento"
                        label="Persona de Mantenimientos"
                      ></v-text-field>
                      </v-col>
                   

                    <v-col
                      cols="5"
                      sm="5"
                      md="5"
                    >
                      <v-text-field
                        v-model="editedItem.Mantenimientocel"
                        label="# Telefono Pesona de Mantenimiento"
                      ></v-text-field>
                      </v-col>
                   </v-row>

                    </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>

                  <!-- Informacion de Credito -->
                  <v-expansion-panels ac>
                  <v-expansion-panel :key="1">
                  <v-expansion-panel-header class="h4" color="#E3F2FD" >Informacion de Credito</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    
                    
                    <v-col
                      cols="11"
                      sm="11"
                      md="11"
                    >
                    <v-select v-model="editedItem.Estado" :items="ECliente2" 
                    label="Estatus"
                    >
                     </v-select>
                    </v-col>

                    <v-row 
                    >
                      <div>Limite de Credito
                        <v-col
                          cols="5"
                          sm="5"
                          md="5"
                        >
                          <vue-numeric 
                          v-bind:precision="2"
                          currency="L"
                          separator="," 
                          v-model="editedItem.Limite"
                          label="Precio_Venta" 
                          ></vue-numeric >
                        </v-col>
                      </div>
                      <div>Dias de Credito
                        <v-col
                          cols="5"
                          sm="5"
                          md="5"
                        >
                          <vue-numeric
                          v-model="editedItem.Dias_Credito"
                          label="Dias de Credito" 
                          ></vue-numeric >
                        </v-col>
                      </div>
                      </v-row>
                      <v-row> 
                        <div>% De Interes
                        <v-col
                          cols="5"
                          sm="5"
                          md="5"
                        >
                          <vue-numeric
                          v-model="editedItem.Interes"
                          label="% De Interes" 
                          ></vue-numeric >
                        </v-col>
                    <v-col
                      cols="10"
                      sm="10"
                      md="10"
                    >
                    <v-select v-model="editedItem.Facturar" :items="FacturarV" 
                    label="Permitir Credito Vencido"
                    >
                     </v-select>
                    </v-col>
                    </div>
                    </v-row>
                      

                  </v-expansion-panel-content>
                  </v-expansion-panel>
                  </v-expansion-panels >
                  
                  <!-- Direccion -->
                  <v-expansion-panels ac>
                  <v-expansion-panel :key="1">
                  <v-expansion-panel-header class="h3" color="#BBDEFB">Informacion Domiciliar</v-expansion-panel-header>
                  <v-expansion-panel-content>
                      
                      <v-col
                      cols="11"
                      sm="11"
                      md="11"
                    >
                    <v-select v-model="editedItem.Iddepartamento" :items="Departamento" 
                    label="Departamento"
                      @change="ciudadesselect"
                    >
                     </v-select>
                    </v-col>

                    <v-col
                      cols="11"
                      sm="11"
                      md="11"
                    >
                    <v-select v-model="editedItem.Idciudad" :items="Ciudad" 
                    label="Ciudad"
                    >
                     </v-select>
                    </v-col>
                    
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.Direccion"
                        label="Direccion"
                      ></v-text-field>
                      </v-col>
                    


                  </v-expansion-panel-content>
                  </v-expansion-panel>
                  </v-expansion-panels >
                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                      v-show="valida"
                    >
                        <v-text-field class="red--text" v-for="v in validamensaje" :key="v" v-text="v">                    
                        </v-text-field>
                    </v-col>
                    
                  </v-row>

                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="Guardar"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="addmodal" max-width="500px">
            <v-card>
              <v-card-title class="headline" v-if="addaction==1"> Desea Activar </v-card-title>
              <v-card-title class="headline" v-if="addaction==2"> Desea Desactivar </v-card-title>
              
              <v-card-text > Estas a Punto de: </v-card-text>
                <span v-if="addaction==1"> Activar </span>
                <span  v-if="addaction==2"> Desactivar</span>
                el item {{addnombre}}
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="activardesactivarcerrar">Cancel</v-btn>
                  <v-btn v-if="addaction==1" color="blue darken-1" text @click="activar">Activar</v-btn>
                  <v-btn v-if="addaction==2" color="blue darken-1" text @click="desactivar">Desactivar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
           
      
      <template v-slot:[`item.actions`]="{ item }">  
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
        <template v-if="item.condicion">
          <v-icon
            small
            
            @click="activardesacticarmostrar(2,item)"
          >
            block
          </v-icon>    
        </template>
        <template v-else>
          <v-icon
            small
            @click="activardesacticarmostrar(1,item)"
          >
            check
          </v-icon>    
        </template>

      </template>

     <template v-slot:[`item.condicion`]="{ item }">
        <div v-if="item.condicion">
          <span class="blue--text">Activo</span>
        </div>
        <div v-else>
          <span class="red--text">Inactivo</span>
        </div>
      </template>



      <template v-slot:no-data
      >
        <v-btn
        loading
        loading-text="Loading... Please wait"
          color="primary"
          @click="listar"
        >
          aaaaaaaaaaaaaaaaaaaaa
        </v-btn>
      </template>
    </v-data-table>
</template>


<script>
import axios from 'axios'
export default {

  data: () => ({
    
    dialog: false,
    search:'',
    dialogDelete: false,
    clientes:[],
    ECliente2:[{text:'Facturar Dentro del Limite de Credito',value:1},{text:'Facturar sin Limite de Credito',value:2},{text:'Nunca Facturar',value:3}],
    FacturarV:[{text:'No',value:1},{text:'Si',value:2}],
    RCliente:[{text:'Natural',value:1},{text:'Juridica',value:2}],
    Clasificacion:[],
    Vendedor:[],
    Departamento:[],
    Ciudad:[],
    headers: [
      { text: 'Acciones', value: 'actions', sortable: false },
      {
        text: 'idcliente',
        align: 'start',
        value: 'idcliente',
      },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Telefono', value: 'telefono' },
      { text: 'email', value: 'email' },
      { text: 'Tipo Documento', value: 'tipo_documento' },
      { text: ' Numero Documento', value: 'num_documento' },
      { text: 'Condicion', value: 'condicion' }
    ],
    TDocumentos:['DNI','Pasaporte','RTN'], 
    editedIndex: -1,
    Idcliente: '',
    editedItem: {
      Nombre: '',
      Num_Documento:'',
      Telefono:'',
      Celular:'',
      Email:'',
      Contacto:'',
      Limite:'',
      Dias_Credito:0,
      Estado:'',
      Idclasificacion:'',
      Idvendedor:'',
      Interes:'',
      Gerente:'',
      Gercel:'',
      Pagador:'',
      Pagcel:'',
      Mantenimiento:'',
      Mantenimientocel:'',
      Facturar:'',
      Tcliente:'',
      Iddepartamento:'',
      Idciudad:'',
      Direccion:'',
    },
      valida:0,
      validamensaje:[],
      addmodal:0,
      addaction:0,
      addnombre:'',
      adid:'',
      campo_bloqueado:0,
      Tipo_Documento:'',
  }),

 computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Cliente' : 'Editando Cliente'
    },
  },

  created () {
    this.listar()
    this.Selects()
  },

   watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
    methods: {
        listar(){
                let me=this;
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                axios.get('api/Clientes/Listar',configuracion).then(function(response){
                    me.clientes=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },

   
        Selects(){
                let me=this;  
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                var clasificacionesaray=[];
                axios.get('api/Clasificaciones/Select',configuracion).then(function(response){
                    clasificacionesaray=response.data;
                    clasificacionesaray.map(function(x)
                    {
                      me.Clasificacion.push({text:x.nombre,value:x.idclasificacion})
                    });


                }).catch(function(error){
                    console.log(error);
                });

                var vendedoresaray=[];
                axios.get('api/Vendedores/Select',configuracion).then(function(response){
                    vendedoresaray=response.data;
                    vendedoresaray.map(function(x)
                    {
                      me.Vendedor.push({text:x.nombre,value:x.idvendedor})
                    });


                }).catch(function(error){
                    console.log(error);
                });

                var departamentosaray=[];
                axios.get('api/departamentos/Select',configuracion).then(function(response){
                    departamentosaray=response.data;
                    departamentosaray.map(function(x)
                    {
                      me.Departamento.push({text:x.nombre,value:x.iddepartamento})
                    });


                }).catch(function(error){
                    console.log(error);
                });

        

            },

    ciudadesselect()
            {
              this.Ciudad= [];
              let me=this;  
              let header={"Authorization":"Bearer "+this.$store.state.token};
              let configuracion={headers:header};
              var ciudadesaray=[];
                axios.get('api/ciudades/Select/'+this.editedItem.Iddepartamento,configuracion).then(function(response){
                    ciudadesaray=response.data;
                    ciudadesaray.map(function(x)
                    {
                      me.Ciudad.push({text:x.nombre,value:x.idciudad})
                    });


                }).catch(function(error){
                    console.log(error);
                });
            },

    editItem (item) {
      this.Idcliente = item.idcliente
      this.editedItem.Nombre = item.nombre
      this.Tipo_Documento = item.tipo_documento
      this.editedItem.Num_Documento = item.num_documento
      this.editedItem.Telefono = item.telefono
      this.editedItem.Celular = item.celular
      this.editedItem.Email = item.email
      this.editedItem.Contacto=item.contacto
      this.editedItem.Limite=item.limite
      this.editedItem.Dias_Credito=item.dias
      this.editedItem.Estado=item.estado
      this.editedItem.Idclasificacion = item.idclasificacion;
      this.editedItem.Idvendedor = item.idvendedor;
      this.editedItem.Interes= item.interes;
      this.editedItem.Gerente= item.gerente;
      this.editedItem.Gercel= item.gercel;
      this.editedItem.Pagador= item.pagador;
      this.editedItem.Pagcel= item.pagcel;
      this.editedItem.Mantenimiento=item.mantenimiento;
      this.editedItem.Mantenimientocel=item.mantenimientocel;
      this.editedItem.Facturar=item.facturar;
      this.editedItem.Tcliente=item.tCliente;
      this.editedItem.Direccion=item.direccion;
      this.editedItem.Iddepartamento=item.iddepartamento;
      this.editedItem.Idciudad=item.idciudad;
      this.ciudadesselect();
      this.editedIndex=1;
      this.campo_bloqueado=1;
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.limpiar();
      this.dialog = false;
    },

    closeDelete () {
      
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.limpiar();

      })
    },

    limpiar () {
      this.editedIndex=-1;
      this.campo_bloqueado=0;
      this.adid="";
      this.validamensaje=""; 
      this.Idcliente ="";
      this.editedItem.Nombre ="";
      this.Tipo_Documento ="";
      this.editedItem.Num_Documento ="";
      this.editedItem.Telefono ="";
      this.editedItem.Celular ="";
      this.editedItem.Email ="";
      this.editedItem.Contacto="";
      this.editedItem.Limite=0;
      this.editedItem.Dias_Credito=0;
      this.editedItem.Estado="";
      this.editedItem.Idclasificacion ="";
      this.editedItem.Idvendedor ="";
      this.editedItem.Interes=0;
      this.editedItem.Gerente="";
      this.editedItem.Gercel="";
      this.editedItem.Pagador="";
      this.editedItem.Pagcel="";
      this.editedItem.Mantenimiento="";
      this.editedItem.Mantenimientocel="";
      this.editedItem.Facturar="";
      this.editedItem.Tcliente="";
      this.editedItem.Iddepartamento="";
      this.editedItem.Idciudad="";
      this.editedItem.Direccion="";
    },

    

    validar () {
      this.valida=0;
      this.validamensaje=[];

      if (this.editedItem.Nombre.length<3 || this.editedItem.Nombre.length>50 )
      {
          this.validamensaje.push("*El nombre debe tener mas de 3 Caracteres y Menos de 50 caracteres")
      }

      if(this.editedItem.Contacto.length<=5 )
      {
        this.validamensaje.push("*El Nombre de Concacto debe tener mas de 5 Caracteres")
      }

      if(this.editedItem.Telefono.length<=5 )
      {
        this.validamensaje.push("*El Telefono debe tener mas de 5 Caracteres")
      }

      if(this.editedItem.Idvendedor.length<1)
      {
        this.validamensaje.push("*Seleccion Un Vendedor")
      }    
      
      var emailc=this.editedItem.Email
      this.pruebaemail(emailc)
        
      
      if(this.editedItem.Num_Documento.length<=5 )
      {
        this.validamensaje.push("*El Numero de Documento debe tener mas de 5 Caracteres")
      }
     
      if (this.editedItem.Tcliente.length<1)
      {
          this.validamensaje.push("*Ingrese una Razon Social.");
      }
      
      if (this.editedItem.Idclasificacion.length<1)
      {
          this.validamensaje.push("*Ingrese una Razon Social.");
      }
      
      if (this.editedItem.Estado.length<1)
      {
          this.validamensaje.push("*Ingrese un Status del Cliente.");
      }
      
      if (this.editedItem.Iddepartamento.length<1)
      {
          this.validamensaje.push("*Ingrese un Departamento en la Pestaña Direcciones.");
      }
      
      if (this.editedItem.Idciudad.length<1)
      {
          this.validamensaje.push("*Ingrese una Ciudad en la Pestaña Direcciones.");
      }

      if (this.editedItem.Direccion.length<10)
      {
          this.validamensaje.push("*La Direccion debe de tener mas de 10 Caracteres.");
      }

      if (this.editedItem.Gerente.length<10)
      {
          this.validamensaje.push("*El Nombre del gerente debe tener mas de 10 Caracteres.");
      }

      if (this.editedItem.Celular.length<5)
      {
          this.validamensaje.push("*El numero de telefono tener mas de 5 Caracteres.");
      }
      
      if (!this.editedItem.TDocumentos)
      {
          this.validamensaje.push("*El Tipo de Documento no es Valido.");
      }

      if (this.validamensaje.length)
      {
        this.valida=1;
      }
      return this.valida;
    },

pruebaemail (valor){
	var re=/^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
	if(!re.exec(valor)){
		this.validamensaje.push("*El Email no es valido")
	}
	},

 activardesacticarmostrar (accion,item) {
      
      this.addmodal=1;
      this.addnombre=item.nombre;
      this.adid=item.idcliente;
      if (accion==1)
      {
        this.addaction=1;
      }
      else if(accion==2) {
        this.addaction=2;
      }
      else{
        this.addmodal=0;
      }
  
    },



activar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Clientes/Activar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
    },

desactivar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Clientes/DesActivar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

activardesactivarcerrar () {
      this.addmodal=0;
    },


Guardar () {
      let ma=this;
      if (ma.validar()){
        return; 
      }

      if (this.editedIndex > -1) {
        //actualizar
        let me=this.editedItem;
        let header={"Authorization":"Bearer "+this.$store.state.token};
        let configuracion={headers:header};
        axios.put('api/Clientes/Actualizar',{
          'idcliente':this.Idcliente,
          'nombre':me.Nombre,        
          'contacto':me.Contacto,
          'telefono':me.Telefono,
          'celular':me.Celular,
          'idvendedor':me.Idvendedor,
          'email':me.Email,
          'tipo_documento':this.Tipo_Documento,
          'num_documento':me.Num_Documento,
          'tCliente':me.Tcliente,          
          'idclasificacion':me.Idclasificacion,
          'gerente':me.Gerente,
          'gercel':me.Gercel,
          'pagador':me.Pagador,          
          'pagcel':me.Pagcel,          
          'mantenimiento':me.Mantenimiento,          
          'mantenimientocel':me.Mantenimientocel,          
          'estado':me.Estado,
          'limite':me.Limite,
          'dias':me.Dias_Credito,
          'interes':me.Interes,
          'facturar':me.Facturar,          
          'iddepartamento':me.Iddepartamento,                    
          'idciudad':me.Idciudad,                    
          'direccion':me.Direccion,                    
          },configuracion).then(function(response)
            {
              ma.close();
              ma.listar();
              ma.limpiar()
            }).catch(function(error)
            {
              console.log(error);
            });

      } else {
        let me=this.editedItem;
        axios.post('api/Clientes/Crear',{
         'nombre':me.Nombre,        
          'contacto':me.Contacto,
          'telefono':me.Telefono,
          'celular':me.celular,
          'idvendedor':me.Idvendedor,
          'email':me.Email,
          'tipo_documento':this.Tipo_Documento,
          'num_documento':me.Num_Documento,
          'tCliente':me.Tcliente,          
          'idclasificacion':me.Idclasificacion,
          'gerente':me.Gerente,
          'gercel':me.Gercel,
          'pagador':me.Pagador,          
          'pagcel':me.Pagcel,          
          'mantenimiento':me.Mantenimiento,          
          'mantenimientocel':me.Mantenimientocel,          
          'estado':me.Estado,
          'limite':me.Limite,
          'dias':me.Dias_Credito,
          'interes':me.Interes,
          'facturar':me.Facturar,          
          'iddepartamento':me.Iddepartamento,                    
          'idciudad':me.Idciudad,                    
          'direccion':me.Direccion,                              
          },configuracion).then(function(response)
            {
              ma.close()
              ma.listar()
              ma.limpiar()  
            }).catch(function(error)
            {
              console.log(error);
            });
      }   
    },
  }, 
}

</script>