<template>
    <v-data-table
      :headers="headers"
      :items="productos"      
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn @click="crearPDF()"><v-icon>print</v-icon></v-btn>
          <v-toolbar-title>Productos</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search" @keypress.enter="listar()" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
          <v-btn @click="listar()" color="primary" dark class="mb-2"  >Buscar</v-btn>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="1000px"
            persistent
          >

            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="limpiar()"
              >
                Nuevo
              </v-btn>
            
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>

                      <v-col
                      cols="12"
                      sm="6"
                      md="5"
                    >
                      <v-text-field
                        v-model="editedItem.Codigo" :disabled="campo_bloqueado"
                        label="codigo"
                      ></v-text-field>

                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="5"
                    >
                    <v-select v-model="IdCategoria" :items="categorias" 
                    label="Categoria"
                    >
                     </v-select>
                    
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.Nombre"
                        label="Nombre"
                      ></v-text-field>
                    </v-col>

                    <v-expansion-panels ac>
                      <v-expansion-panel :key="1" 	
                      >
                        <v-expansion-panel-header class="h3" >Detalle de Producto</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div>Existencias
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="12"
                                  >
                                    <vue-numeric
                                    v-model="editedItem.Stock" :disabled="campo_bloqueado"
                                    Anonymous
                                    separator="," 
                                    label="stock" 
                                    ></vue-numeric >
                                  </v-col>
                                  </div>

                                  <div>Precio de Venta
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="12"
                                  >
                                    <vue-numeric 
                                    v-bind:precision="2"
                                    currency="L"
                                    separator="," 
                                    v-model="editedItem.Precio_Venta"
                                    label="Precio_Venta" 
                                    ></vue-numeric >
                                  </v-col>
                                  </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
                <v-row>
                   <v-expansion-panels ac>
                      <v-expansion-panel :key="1" 	
                      >
                        <v-expansion-panel-header @click="llenardiv()" class="h3" >Imagen Producto</v-expansion-panel-header>
                        <v-expansion-panel-content>
                             <input
                                id="tfile"
                                type="file"
                                accept="image/jpeg/*"
                                @change="uploadImage()"
                              />
                              <!--
                              <v-btn @click="llenardiv()">Obtener Imagen</v-btn>
                               -->
                                <div style="width:400px;height:400px;" id="imgproducto">.
                                </div>
                               
                      </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>            
                  </v-row>                                          
                   <v-text-field class="red--text" v-for="v in validamensaje" :key="v" v-text="v">                    
                        </v-text-field>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="Guardar"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="addmodal" max-width="500px">
            <v-card>
              <v-card-title class="headline" v-if="addaction==1"> Desea Activar </v-card-title>
              <v-card-title class="headline" v-if="addaction==2"> Desea Desactivar </v-card-title>
              
              <v-card-text > Estas a Punto de: </v-card-text>
                <span v-if="addaction==1"> Activar </span>
                <span  v-if="addaction==2"> Desactivar</span>
                el item {{addnombre}}
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="activardesactivarcerrar">Cancel</v-btn>
                  <v-btn v-if="addaction==1" color="blue darken-1" text @click="activar">Activar</v-btn>
                  <v-btn v-if="addaction==2" color="blue darken-1" text @click="desactivar">Desactivar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>


          
        </v-toolbar>
      </template>
           
      
      <template v-slot:[`item.actions`]="{ item }">  
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
        <template v-if="item.condicion">
          <v-icon
            small
            
            @click="activardesacticarmostrar(2,item)"
          >
            block
          </v-icon>    
        </template>
        <template v-else>
          <v-icon
            small
            @click="activardesacticarmostrar(1,item)"
          >
            check
          </v-icon>    
        </template>

      </template>

     <template v-slot:[`item.condicion`]="{ item }">
        <div v-if="item.condicion">
          <span class="blue--text">Activo</span>
        </div>
        <div v-else>
          <span class="red--text">Inactivo</span>
        </div>
      </template>

      <template v-slot:no-data
      >
        <v-btn
        loading
        loading-text="Loading... Please wait"
          color="primary"
          @click="listar"
        >
          aaaaaaaaaaaaaaaaaaaaa
        </v-btn>
      </template>
    </v-data-table>
</template>


<script>

import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export default {

  data: () => ({
    dialog: false,
    search:'',
    dialogDelete: false,
    productos:[],
    headers: [
      { text: 'Acciones', value: 'actions', sortable: false },
      { text: 'IdProducto', value: 'idproducto',  sortable: false},
      { text: 'Codigo', value: 'producto',  sortable: false},
      { text: 'Nombre', value: 'nombre' },
      { text: 'Categoria', value: 'ncategoria' },
      { text: 'Existencias', value: 'stock' },
      { text: 'Precio', value: 'precio_venta' },
      { text: 'Condicion', value: 'condicion' }
    ],
    editedIndex: -1,
    editedItem: {
      Codigo:'',
      Nombre: '',
      Stock: 0,
      Precio_Venta: 0,
    },
    IdCategoria:'',
    IdProducto: '',
    categorias:[],
      valida:0,
      validamensaje:[],
      addmodal:0,
      addaction:0,
      addnombre:'',
      adid:'',
      campo_bloqueado:false,
      imageSrc:''
  }),
  



 computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Producto' : 'Editando Producto'
    },
  },

  created () {
    this.listar()
    this.SelectCategoria()
  },

   watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
    methods: {
    
            uploadImage() {
                  const file = document.querySelector('input[type=file]').files[0]
                  const reader = new FileReader()
                let rawImg;
                reader.onloadend = () => {
                  this.imageSrc = reader.result;
                  console.log(this.imageSrc);
                  this.llenardiv();
                }
                reader.readAsDataURL(file);
               // console.log(file)
                //alert(this.imageSrc);
                
                },

              
 sleep(milliseconds) {
              const date = Date.now();
              let currentDate = null;
              do {
                currentDate = Date.now();
              } while (currentDate - date < milliseconds);
            },

           dowImage() {
           this.loading=true;
           let me=this;    
           me.imageSrc='';
           let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                axios.get('api/Productos/ObtenerImg/'+this.IdProducto,configuracion).then(function(response){
                  me.imageSrc=response.data['imgbase'];
                  //alert(me.imageSrc)
                  //alert("entro")
                  if (me.imageSrc!=null && me.imageSrc.length>10 ){
                    //alert("paso")
                     if(document.getElementById("id")!=null){
                       var x=document.getElementById("id")
                       document.getElementById("imgproducto").removeChild(x)
                      }
                        me.llenardiv()
                        //document.getElementById("imgproducto").innerHTML = '&nbsp;';
                    }
                }).catch(function(error){
                    console.log(error);
                }).finally(() => this.loading = false);
                 //alert("termino Primero")
          },

          llenardiv(){
                        let me=this; 
                        if(me.imageSrc==null || me.imageSrc.length<10 )
                        {return;}
                        if(document.getElementById("id")!=null){
                       var x=document.getElementById("id")
                       document.getElementById("imgproducto").removeChild(x)
                      }
                        //document.getElementById("imgproducto").innerHTML = '&nbsp;';
                        var image = document.createElement("img");
                        var imageParent = document.getElementById("imgproducto");
                        image.id = "id";
                        image.className = "class";
                        image.src = me.imageSrc;            // image.src = "IMAGE URL/PATH"
                        image.width="300";
                        image.height="300";
                        imageParent.appendChild(image);
          },

            crearPDF(){
                var columns = [
                    {title: "Nombre", dataKey: "nombre"},
                    {title: "Código", dataKey: "producto"}, 
                    {title: "Categoría", dataKey: "ncategoria"}, 
                    {title: "Stock", dataKey: "stock"},
                    {title: "Precio Venta", dataKey: "precio_venta"}
                ];
                var rows = [];

                this.productos.map(function(x){
                    rows.push({nombre:x.nombre,producto:x.producto,ncategoria:x.ncategoria,stock:x.stock,precio_venta:x.precio_venta});
                });

                // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Artículos", 40, 30);
                    }
                });
                doc.save('Productos.pdf');
            },

        listar(){
                let me=this;
                let url='';
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                if(me.search.length<=0){
                    url='api/Productos/ListarSaldos';
                }else{
                  url='api/Productos/ListarIngreso/'+me.search;
                }
                axios.get(url,configuracion).then(function(response){
                    me.productos=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },

   SelectCategoria(){
                let me=this;  
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                var categiriasaray=[];
                axios.get('api/Categorias/Select',configuracion).then(function(response){
                    categiriasaray=response.data;
                    categiriasaray.map(function(x)
                    {
                      me.categorias.push({text:x.nombre,value:x.idcategoria})
                    });


                }).catch(function(error){
                    console.log(error);
                });
            },

    editItem (item) {
      //this.limpiar();
      this.IdProducto = item.idproducto;
      this.editedItem.Nombre = item.nombre;
      this.IdCategoria = item.idcategoria;
      this.editedItem.Codigo = item.producto;
      this.editedItem.Precio_Venta = item.precio_venta;
      this.editedItem.Stock = item.stock;
      this.editedIndex=1;
      this.campo_bloqueado=true;
      this.imageSrc = item.imgbase;
      this.dialog = true
      
      var inputImage = document.getElementById("tfile");
      if(inputImage!=null){
        inputImage.value = '';
      }
      
      //this.dowImage()
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    limpiar () {
      this.editedIndex=-1;
      this.editedItem.Nombre="";
      this.IdCategoria="";
      this.editedItem.idproducto="";
      this.editedItem.Codigo="";
      this.editedItem.Stock="";
      this.editedItem.Precio_Venta="";
      this.campo_bloqueado=false;
      if (this.imageSrc!=null && this.imageSrc.length>10 ){
        if(document.getElementById("id")!=null){
          var x=document.getElementById("id")
          document.getElementById("imgproducto").removeChild(x)
        }
      }
      this.imageSrc='';      
    },

    validar () {
      this.valida=0;
      this.validamensaje=[];

      if (this.editedItem.Nombre.length<3 || this.editedItem.Nombre.length>50)
      {
          this.validamensaje.push("*El nombre debe tener mas de 3 Caracteres y Menos de 50 caracteres.");
      }

      if (this.editedItem.Codigo.length<3 || this.editedItem.Codigo.length>20)
      {
          this.validamensaje.push("*El Codigo debe tener mas de 3 Caracteres y Menos de 20 caracteres.");
      }

      if (!this.IdCategoria ||this.IdCategoria ==="")
      {
        this.validamensaje.push("*Selecione una Categoria.");
      }

      if (!this.editedItem.Precio_Venta || this.editedItem.Precio_Venta.value<=0 )
      {
        this.validamensaje.push("*El Precio no puede ser menor o igual a 0.");
      }

      if (this.validamensaje.length)
      {
        this.valida=1;
      }
      return this.valida;
    },

 activardesacticarmostrar (accion,item) {
      
      this.addmodal=1;
      this.addnombre=item.nombre;
      this.adid=item.idproducto;
      if (accion==1)
      {
        this.addaction=1;
      }
      else if(accion==2) {
        this.addaction=2;
      }
      else{
        this.addmodal=0;
      }
  
    },



activar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Productos/Activar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

    

desactivar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Productos/DesActivar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

activardesactivarcerrar () {
      this.addmodal=0;
    },



Guardar () {
      let ma=this;
      let header={"Authorization":"Bearer "+this.$store.state.token};
      let configuracion={headers:header};
      if (ma.validar()){
        return; 
      }

      if (this.editedIndex > -1) {
        //actualizar
        let me=this.editedItem;
        axios.put('api/Productos/Actualizar',{
          'idproducto':this.IdProducto,
          'idcategoria':this.IdCategoria,
          'producto':me.Codigo,
          'nombre':me.Nombre,
          'precio_venta':me.Precio_Venta,
          'imgbase':this.imageSrc
        },configuracion).then(function(response)
            {
              ma.close()
              ma.listar()
              ma.limpiar()  
            }).catch(function(error)
            {
              console.log(error);
            });
      } else {
        let me=this.editedItem;
        axios.post('api/Productos/Crear',
        {
          'producto':me.Codigo,          
          'idcategoria':this.IdCategoria,
          'nombre':me.Nombre,
          'precio_venta':me.Precio_Venta,
          'imgbase':this.imageSrc
        },configuracion).then(function(response)
            {
              ma.close()
              ma.listar()
              ma.limpiar()  
            }).catch(function(error)
            {
              console.log(error);
            });
      }   
    },
  }, 
}
</script>

<style scoped>
#imagen
      {
      margin-top: 10px;
      width:"400px";
      height:"400px";
      }
</style>