
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Categoria from '../components/Categoria.vue'
import Producto from '../components/Producto.vue'
import VueMask from 'v-mask'
import VueNumeric from 'vue-numeric'
import Rol from '../components/Rol.vue'
import Usuario from '../components/Usuario.vue'
import Departamento from '../components/Departamento.vue'
import Ciudad from '../components/Ciudad.vue'
import Clasificacion from '../components/Clasificacion.vue'
import Vendedor from '../components/Vendedor.vue'
import Cliente from '../components/Cliente.vue'
import Login from '../components/Login.vue'
import store from '../store'
import Almacen from '../components/Almacen.vue'
import Cotizacion from '../components/Cotizacion.vue'
import GraficaVentas from '../components/GraficaVentas.vue'
import ConsultaExistencias from '../components/ConsultaExistencias.vue'
import ConsultaVentas from '../components/ConsultaVentas.vue'


Vue.use(VueRouter)
Vue.use(VueMask)


const routes =  [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta :{
      administrador :true,
      bodega: true,
      vendedores: true
    }
  },
  {
    path: '/categorias',
    name: 'categorias',
    component: Categoria,
    meta :{
      administrador :true,
      bodega: true,
      vendedores: true,
    }
  },
  {
    path: '/productos',
    name: 'productos',
    component: Producto,
    meta :{
      administrador :true,
      bodega: true
    }
  },
  
  {
    path: '/roles',
    name: 'roles',
    component: Rol,
    meta :{
      administrador :true
    }
  },
  
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Usuario,
    meta :{
      administrador :true
    }

  },
  
  {
    path: '/departamentos',
    name: 'departamentos',
    component: Departamento,
    meta :{
      administrador :true
    }
  },
  {
    path: '/ciudades',
    name: 'ciudades',
    component: Ciudad,
    meta :{
      administrador :true    }
  },
  {
    path: '/clasificaciones',
    name: 'clasificaciones',
    component: Clasificacion,
    meta :{
      administrador :true    }
  },
  {
    path: '/vendedores',
    name: 'vendedores',
    component: Vendedor,
    meta :{
      administrador :true
    }
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: Cliente,
    meta :{
      administrador :true
    }
  },

  {
    path: '/Almacenes',
    name: 'Almacenes',
    component: Almacen,
    meta :{
      administrador :true,
      bodega: true
    }
  },

  {
    path: '/cotizaciones',
    name: 'cotizaciones',
    component: Cotizacion,
    meta :{
      administrador :true,
      bodega: true,
      vendedores: true
    }
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta : {
      libre: true
    }
  },

  {
    path: '/graficaventas',
    name: 'graficaventas',
    component: GraficaVentas,
    meta :{
      administrador :true,
    }
  },
  
  {
    path: '/consultaexistencias',
    name: 'consultaexistencias',
    component: ConsultaExistencias,
    meta :{
      administrador :true,
      vendedores: true,
      bodega: true
    }
  },

  {
    path: '/consultaventas',
    name: 'consultaventas',
    component: ConsultaVentas,
    meta :{
      administrador :true,
    }
  },
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)){
    next()
  } else if (store.state.usuario && store.state.usuario.rol== 'Administrador'){
    if (to.matched.some(record => record.meta.administrador)){
      next()
    }
  }else if (store.state.usuario && store.state.usuario.rol== 'Bodega'){
    if (to.matched.some(record => record.meta.almacenero)){
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.rol== 'Vendedores'){
    if (to.matched.some(record => record.meta.vendedores)){
      next()
    }
  }else{
    next({
    name: 'login'
    })
  }
})

export default router
