<template>
    <v-data-table
      :headers="headers"
      :items="roles"
      sort-by="Idcategorias"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Roles</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
          </v-dialog>
        </v-toolbar>
      </template>
           
     <template v-slot:[`item.condicion`]="{ item }">
        <div v-if="item.condicion">
          <span class="blue--text">Activo</span>
        </div>
        <div v-else>
          <span class="red--text">Inactivo</span>
        </div>
      </template>



      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="listar"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
</template>


<script>
import axios from 'axios'
export default {

  data: () => ({
    dialog: false,
    search:'',
    dialogDelete: false,
    roles:[],
    headers: [
      {
        text: 'IDrol',
        align: 'start',
        value: 'idrol',
      },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Condicion', value: 'condicion' }
    ],
    editedIndex: -1,
    editedItem: {
      Idcategoria: '',
      Nombre: '',
      categoria:'',
    },
  }),

 computed: {

  },

  created () {
    this.listar()
  },

   watch: {

    
  },
    methods: {
        listar(){
                let me=this;
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};

                axios.get('api/Roles/Listar',configuracion).then(function(response){
                    me.roles=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },
 
    },
}

</script>